<template>
  <div class="d-flex flex-column align-center">
    <v-row class="justify-center" style="padding-top: 105px">
      <img
        :src="require('@/assets/noInvoice.png')"
        alt="folder svg"
        class="folder__img"
      />
    </v-row>

    <!-- instructions when there is no invoice -->

    <v-row
      class="justify-center px-8 text-center text-md-start"
      style="padding-top: 40px"
    >
      <p class="noInvoice text-center">
        No invoice/transaction is awaiting action
      </p>
    </v-row>

    <!-- instructions when there is no invoice -->
    <div class="text-center pt-4" style="max-width: 396px">
      <p class="p-guide mb-0">
        Start receiving vendor invoices at your hyphen invoice box
        <span class="font-weight-bold">{{
          organizationToken.data.hypn_email
        }}</span
        >.
      </p>
      <p class="p-guide pt-0 mt-0">
        or Send Invoice to customers
        <span class="font-weight-bold link__text" @click="openCreateInvoice"
          >here</span
        >
      </p>
    </div>
    <div class="d-flex justify-center align-center px-8 pt-8">
      <v-card
        class="d-flex flex-row justify-center align-center info__card"
        elevation="0"
      >
        <v-avatar class="d-flex align-center ma-4" color="#F9EED2" size="40">
          <img :src="require('@/assets/noforminfo.svg')" alt="" />
        </v-avatar>
        <v-card-text class="p-guide pl-0" style="background-color: #fff">
          To import existing contractor invoices to hyphen, forward invoice
          attachements to
          <span class="font-weight-bold">{{
            organizationToken.data.hypn_email
          }}</span>
          or ask your vendors to send directly
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      //
    };
  },

  computed: {
    ...mapGetters({
      organizationToken: "organizations/OrganToken",
    }),
  },
  methods: {
    openCreateInvoice() {
      // open Make customer Invoice page

      this.$router.push({ name: "NewInvoice" });
    },
  },
};
</script>

<style scoped>
.noInvoice {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.73px;
  color: #19283d;
}

.p-guide {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #596a73;
}

.info__card {
  max-width: 455px;
  background: #ffffff;
  border: 1px solid #fbf4e4;
  box-sizing: border-box;
  border-radius: 4px;
}

.info__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #596a73;
}

.folder__img {
  width: 76px;
  max-width: 100px;
  height: 68.88px;
}
.link__text {
  cursor: pointer;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .errorCode {
    font-size: 150px;
  }

  .errror-message {
    font-size: 24px;
  }

  .info__text {
    font-size: 9px;
  }

  .info__card {
    max-width: 360px;
  }

  .p-guide {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    /* or 167% */

    text-align: center;

    color: #596a73;
  }
}
</style>
